/*>>>>>>>> Contact Section styling (landing-8)<<<<<<<<<*/
.omga-08__contact-section{
  position: relative;
  z-index: 1;

  @include tablet{
    margin-top: 150px;
  }
  .contact-shape{
    position: absolute;
    width: 150%;
    bottom: 48%;
    left: -5%;
    @include mobile-lg{
      bottom: 48%;
    }
    @include tablet{
      bottom: 48%;
    }
    @include desktops{
      bottom: 47%;
    }
    img{
      max-width: unset;
      width: 100%;
    }
  }
  &:after{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 49%;
    content: "";
    width: 100%;
    background: $l8-secondary-color;
    z-index: -1;
  }
  .form-title-block{
    margin-bottom: 3px;
    .f-title{
      color: $l8-primary-heading;
      font-size: 60px;
      font-weight: 700;
      letter-spacing: -1.88px;
      line-height: 70px;
    }
    p{
      color: $l8-primary-text !important;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
    }
  }
}
.omga-08__contact-form{
  box-shadow: 0 20px 61px rgba(65, 62, 101, 0.14);
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-top: 30px;
  @include mobile-lg{
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 55px;
    padding-top: 60px;
  }
  .form-title-block{
    text-align: center;
    padding-bottom:10px;
    .f-title{
      color: $l8-primary-heading;
      font-size: 60px;
      font-weight: 700;
      letter-spacing: -1.88px;
      line-height: 70px;
    }
    p{
      color: $l8-primary-text!important;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
      display: flex;
      justify-content: center;
    }
  }
  .form-control{
    height: 73px;
    border-radius: 10px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    color: #9c9aaa;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    margin-bottom: 17px;
    padding-left: 25px;
  }
  textarea{
    min-height: 115px;
    padding-top: 28px;
  }
  .agree-block{
    display: flex;
    margin-bottom: 20px;
    padding-top: 10px;
    #agree-check{
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      &~label{
        position: relative;
        min-width: 19px;
        max-height: 19px;
        min-height: 19px;
        border-radius: 3px;
        border: 1.5px solid #9c9aaa;
        margin-right: 15px;
        margin-top: 3px;
        &:before{
          content: "\f00c";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 8px;
          height: 100%;
          width: 100%;
          position: absolute;
          text-align: center;
          opacity: 0;
        }
      }
      &:checked{
        &~label{
          border-color:$l8-secondary-color;
          background: $l8-secondary-color;
          &::before{
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
    p{
      margin-bottom: 0;
      color: $l8-primary-text!important;
      a{
        color: $l8-secondary-color;
      }
    }

  }
}
