.career-page{
  .section-title{
    p{
      margin-top:28px;
    }
    
  }
}
 /*>>>>>>>> JOB area styling <<<<<<<<<*/
  .job-section{
    background: #f7f7fb;
    padding-top: 50px;
    padding-bottom: 65px;
    @include tablet{
      padding-top: 100px;
      padding-bottom: 130px;
    }
  }
  .card-job{
    min-height: 350px;
    box-shadow: 0 2px 4px rgba(14, 86, 124, 0.17);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 35px;
    transition: .4s;
    border-radius: 10px;
    .location{
      color: #8c97ac;
      font-size: 16px;
      font-weight: 300;
      margin-right: 5px;
      display: flex;
      align-items: center;
    }
    
    .card-footer{
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      align-items: center;
      .client-details{
        display: flex;
        flex-direction: column;
    
        .company-title{
          color: #1d293f;
          font-size: 18px;
          font-weight: 500;
          line-height: 1;
        }
        span{
          line-height: 1;
          color: #8c97ac;
          font-size: 13px;
          font-weight: 300;
        }
      }
    }
    .badge{
      height: 30px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 300;
      display: flex;
      align-items: center;
      padding-left: 13px;
      padding-right: 13px;
      &.green{
        background-color: rgba($theme-green-color, 0.1);
        color: $theme-green-color;
      }
      &.red{
        background-color:rgba($theme-red-color, 0.15);;
        color: $theme-red-color;
      }
      &.blue{
        background-color: rgba($theme-blue-color, 0.15);
        color: $theme-blue-color;
      }
    }
    &:hover {
      box-shadow: 0 32px 84px rgba(14, 86, 124, 0.17);
    }
    &.top-only{
      justify-content: flex-start;
      min-height: 100%;
    }
  }
  /*>>>>>>>> feature area styling <<<<<<<<<*/
  .career-feature-section{
    @include tablet{
      padding-bottom: 40px;
    }
  }

  $theme-red-color: #f04037;
  $theme-green-color: #77bf41;
  $theme-purple-color: #a665fb;
  $theme-ash-color: #413e65;
  $theme-blue-color: #5454d4;
  $theme-yellow-color: #fedc5a;
  
  /* Ripple Out */
  @-webkit-keyframes ripple-out {
    100% {
      top: -12px;
      right: -12px;
      bottom: -12px;
      left: -12px;
      opacity: 0;
    }
  }
  @keyframes ripple-out{
    100% {
      top: -12px;
      right: -12px;
      bottom: -12px;
      left: -12px;
      opacity: 0;
    }
  }
  .circle-bg-red{
    background: $theme-red-color;
    &.outer-circle{
      position:relative;
      -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        background: rgba($theme-red-color,.06);
      &:before {
        content: '';
        position: absolute;
        border: rgba($theme-red-color,.08) solid 6px;
        border-radius: 500px;
        right: 0;
        bottom: 0;
        left: 0;
        top:0;
        animation: ripple-out 1.2s linear 1s infinite;
      }
    }
  }
  .circle-bg-green{
    background: $theme-green-color;
    &.outer-circle{
      position:relative;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      background: rgba($theme-green-color,.06);
        &:before {
          content: '';
          position: absolute;
          border: rgba($theme-green-color,.08) solid 6px;
          border-radius: 500px;
          right: 0;
          bottom: 0;
          left: 0;
          top:0;
          animation: ripple-out 1.2s linear 1s infinite;
        }
    }
  }
  .circle-bg-purple{
    background: $theme-purple-color;
    &.outer-circle{
      background: rgba($theme-purple-color,.06);
      position: relative;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      &:before {
        content: '';
        position: absolute;
        border: rgba($theme-purple-color,.08) solid 6px;
        border-radius: 500px;
        right: 0;
        bottom: 0;
        left: 0;
        top:0;
        animation: ripple-out 1.2s linear 1s infinite;
        opacity: 1.6;
      }
    }
  }
  .circle-bg-ash{
    background: $theme-ash-color;
    &.outer-circle{
      background: rgba($theme-ash-color,.06);
      position: relative;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      &:before {
        content: '';
        position: absolute;
        border: rgba($theme-ash-color,.08) solid 6px;
        border-radius: 500px;
        right: 0;
        bottom: 0;
        left: 0;
        top:0;
        animation: ripple-out 1.2s linear 1s infinite;
        opacity: 1.6;
      }
    }
  }
  .circle-bg-blue{
    background: $theme-blue-color;
    &.outer-circle{
      background: rgba($theme-blue-color,.06);
      position: relative;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      &:before {
        content: '';
        position: absolute;
        border: rgba($theme-blue-color,.08) solid 6px;
        border-radius: 500px;
        right: 0;
        bottom: 0;
        left: 0;
        top:0;
        animation: ripple-out 1.2s linear 1s infinite;
        opacity: 1.6;
      }
    }
  }
  .circle-bg-yellow{
    background: $theme-yellow-color;
    &.outer-circle{
      background: rgba($theme-yellow-color,.06);
      position: relative;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      &:before {
        content: '';
        position: absolute;
        border: rgba($theme-yellow-color,.08) solid 6px;
        border-radius: 500px;
        right: 0;
        bottom: 0;
        left: 0;
        top:0;
        animation: ripple-out 1.2s linear 1s infinite;
        opacity: 1.6;
      }
    }
  }

 


