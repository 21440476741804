.blog-page{
  .section-title{
    p{
      margin-top:28px;
    }
    
  }

}
  /*>>>>>>>> BLog Post (Regular) styling <<<<<<<<<*/
  .omega-blog-page-sidebar{
    .single-sidebar{
      background-color: #413e65;
      border-radius: 10px;
      border: 1px solid #eae9f2;
      background-color: #413e65;
      margin-bottom: 33px;
      .sidebar-title{
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.75px;
        line-height: 32px;
        
      }
      .sidebar-search{
        min-height: 71px;
        display: flex;
        align-items: center;
        padding-left: 23px;
        button{
          font-size: 19px;
          color: rgba(255, 255, 255, 0.7);
          height: 100%;
          margin-top:9px ;
        }
        .form-control{
          color: rgba(255, 255, 255, 0.7);
          font-size: 16px;
          font-weight: 300;
          letter-spacing: -0.5px;
          line-height: 26px;
          border: 0;
          background: transparent;
          &::placeholder{
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
      &.post-block{
        padding: 25px;
      }
    }
    .sidebar-post-block{
      border-bottom: 1px solid #524f73;
      padding-bottom: 13px;
      padding-top: 20px;
      &:last-child{
        padding-bottom: 0px;
        border-bottom: 0;
        
      }
      .title{
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.5px;
        line-height: 26px;
        a{
          &:hover{
            opacity: .9;
            color: #fff;
          }
        }
      }
      .date{
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
        font-weight: 300;
        letter-spacing: -0.44px;
        line-height: 26px;
        margin-top: 4px;
      }
    }
    .category-sidebar-list{
      li{
        margin-bottom: 13px;
        &:last-child{
          margin-bottom: 0;
        }
        a{
          color: #ffffff;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.5px;
          line-height: 22px;
          span{
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }
  }
  .blog-post-area{
    padding-bottom: 20px;
    @include tablet{
      padding-bottom: 140px;
    }
  }
  .blog-card{
    border-radius:0 0 10px 10px ;
    transition: .4s;
    &_img{
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
    &_texts{
      border: 1px solid #eae9f2;
      border-top: 0;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 33px;
      padding-right: 33px;
      border-radius:0 0 10px 10px ;
      .post-date{
        color: #767581;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 26px;
        padding-bottom: 17px;
      }
      .post-title{
        color: #19191b;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.66px;
        line-height: 32px;
        padding-bottom: 8px;
      }
      p{
        color: #767581;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 26px;
        margin-bottom: 15px;
      }
      .link-to-more{
        color: #f04037;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.5px;
        line-height: 28px;
        margin-top: 6px;
      }
    }
    &:hover{
      box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
    }
    &--list{
      display: flex;
      flex-direction: column;
      @include tablet{
        flex-direction: row;
      }
      .blog-card_img{
        border-radius:10px 10px 0 0  ;
        @include tablet{
          border-radius:10px 0 0  10px ;
          width: 100%;
          @include tablet{
            min-width:350px;
            max-width:350px;
          }
          @include desktops{
            min-width: 265px;
          }
          @include large-desktops{
            min-width:350px;
            max-width:350px;
          }
        }
        img{
          width: 100%;
          @include desktops{
            max-width: none;
            width: fit-content;
          }
        }
      }
      .blog-card_texts{
        @include tablet{
          border-radius: 0px 10px 10px 0;
          border-top: 1px solid  #eae9f2;
          border-left: 1px solid  #eae9f2;
        }
      }
    }
  }
  .omega-blog-pagination{
    .pagination-list{
      display: flex;
      li{
        width: 38px;
        height: 38px;
        border-radius: 5px;
        border: 1px solid #eae9f2;
        background-color: #ffffff;
        margin: 0 5px;
        display: flex;
          justify-content: center;
        &.exerpt{
          align-items: flex-end;
        }
        a{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        
          border-radius: 5px;
        }
        &:hover{
          border: 1px solid #5454d4;
          background-color: #5454d4;
          color: #fff;
          a{
            color: inherit;
          }
        }
      }
    }
  }


/*>>>>>>>> CTA Section styling <<<<<<<<<*/

  .blog-post-details{
    .post-image{
      img{
        border-radius: 10px;
      }
    }
    .article-title{
      color: #19191b;
      font-size: 24px;
      font-weight: 700;
    }
    p{
      color: #696871;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      line-height: 30px;
      padding-bottom: 10px;
    }
    .quoteed-text{
      display: flex;
      &:before{
        content: url(../image/png/quote-icon.png);
        display: inline-block;
        min-width: 28px;
        max-width: 28px;
        margin-top: 8px;
        margin-right: 23px;
      }
      p{
        color: #696871;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: -0.66px;
        line-height: 34px;
      }
    }
    .related-post-tags{
      display: flex;
      a{
        background: #eae9f2;
        color: #696871;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.5px;
        border-radius: 5px;
        line-height: 1;
        padding: 15px;
        
      }
      li{
        margin: 0 12px 10px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }

  .omega-blog__reply-section{
    background-color: #f7f7fb;
    padding-top: 40px;
    padding-bottom: 40px;
    @include tablet{
      padding-top: 110px;
      padding-bottom: 120px;
    }
    .reply-block-title{
      color: #19191b;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.75px;
      line-height: 32px;
    }
  }
  .omega-blog__reply-form{
  .form-control{
    box-shadow: 0 2px 4px rgba(14, 86, 124, 0.17);
    border-radius: 10px;
    background-color: #ffffff;
    border: 0;
    color: #9c9aaa;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  input{
    height: 60px;
    padding-left: 30px;
  }
  textarea{
    min-height: 171px;
    padding-top: 20px;
    padding-left: 30px;
  }
  .btn{
    width: 259px;
    height: 60px;
    border-radius: 10px;
    overflow: hidden;
  }
  }
 .omega-blog__comments{
   .single-comment{
    box-shadow: 0 2px 4px rgba(14, 86, 124, 0.17);
    border-radius: 10px;
    background-color: #ffffff;
    padding: 25px;
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
    p{
      color: #696871;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      line-height: 30px;
    }
    .user-small-card {
      display: flex;
      margin-bottom: 5px;
      .user-img {
        min-width: 54px;
        max-width: 54px;
        max-height: 54px;
        min-height: 54px;
        border-radius: 500px;
        margin-right: 15px;
        overflow: hidden;
      }
      .user-identity {
        .name {
          color: #19191b;
          font-size: 21px;
          font-weight: 700;
          letter-spacing: -0.66px;
          line-height: 28px;
        }
        .date {
          color: #696871;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: -0.5px;
          line-height: 28px;
        }
      }
     
    }
   }
 } 
