

/*>>>>>>>> Pricing Section <<<<<<<<<*/

.pricing-btns{
  border-radius: 10px;
  padding: 5px;
  background-color: #f7f7fb;
  display: inline-flex;
  max-height:65px;
  a{
    border-radius: 10px 0 0 10px;
    width: 160px;
    height: 55px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: #19191b;
    &+a{
      border-radius:0 10px 10px 0 ;
    }
    &.active{
      background-color: #5454d4;
      color: #fff;
    }
  }
}



.yearly-active{
  .yearly{
    display: block;
  }
  .monthly{
    display: none;
  }
}
.monthly-active{
  .monthly{
    display: block;
  }
  .yearly{
    display: none;
  }
}
/*>>>>>>>> Feature Section <<<<<<<<<*/
.feature-area{
  .section-title{
    h2{
      color: #19191b;
      font-size: 60px;
      font-weight: 700;
      letter-spacing: -1.88px;
      line-height: 84px;
    }
  }
}
.feature-widget{
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  @include large-desktops{
    padding-left: 35px;
    padding-right: 35px;
  }
  .widget-icon{
    min-width: 69px;
    max-width: 69px;
    min-height: 69px;
    max-height: 69px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    margin-bottom: 17px;
    font-size: 25px;
    &.bg{
      &-red{
        background-color: rgba(240, 64, 55, 0.1);
        color: #f04037;
      }
      &-blue{
        background-color: rgba(84, 84, 212, 0.1);
        color: #5454d4;
      }
      &-yellow{
        background-color: rgba(254, 220, 90, 0.1);
        color: #fedc5a;
      }
    }
  }
  .widget-text{
    .title{
      display: block;
      // color: #FFFFFF;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.75px;
      margin-bottom: 14px;
    }
    p{
      // color: rgba(255, 255, 255, 0.7);
      // font-family: "Circular Std";
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 28px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

 /*>>>>>>>> FAQ Section styling <<<<<<<<<*/
 .pricing-page-faq-section{
  .section-title{
    p{
      padding-top: 15px;
    }
  }
  .faq-link{
    margin-top: 85px;
    text-align: center;
    p{
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 28px;
      margin-bottom: 0;
    }
    a{
      color: #f04037;
    }
  }
}



  /*>>>>>>>> Footer area styling <<<<<<<<<*/

 

  .pricing-table{
    overflow: auto;
    padding-bottom: 30px;
    table{
      display: block;
      thead{
        display: block;
        tr{
          display: block;
          .sin-title-box{
            padding: 0 20px;
            height: 60px;
            color: #19191b;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: -0.56px;
          }
        }
      }
      tbody{
        display: block;
        tr{
          display: block;
        }
      }
    .title-col {
        min-width: 290px;
    }
    
    .basic-col {
        min-width: 195px;
    }
    
    .standard-col {min-width: 195px;}
    
    .premium-col {
        min-width: 195px;
    }
    
    .ent-col {
      min-width: 195px;
    }
    .colored-row{
      background: #f7f7fb;
      border-top: 2px solid #fff;
      &:first-child{
        border-top: 0;
        .sin-box{
          &:before{
            height: 50%;
            top: auto;
            bottom: 0;
          }
        }
      }
      .sin-box{
        position: relative;
        padding: 0 20px;
        height: 65px;
        color: #696871;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: -0.56px;
        &:before{
          content: "";
          background: #fff;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          width: 1px;
        }
        &.title-col {
          color: #19191b;
          font-weight: 700;
        }
        .table-icon{
          font-size: 20px;
          &.neg{
            color: #f04037;
          }
          &.pos{
            color: #67d216;;
          }
        }
      }
    }
    .price-widget-block{
      padding-left: 22px;
      margin-top: 50px;
      .title{
        color: #19191b;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.66px;
        padding-bottom: 5px;
      }
      .subtitle{
        color: #9c9aaa;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: -0.46px;
        padding-bottom: 26px;
      }
      .btn{
        width: 167px;
        height: 50px;
        border-radius: 5px;
        border: 1px solid #eae9f2;
        background-color: #ffffff;
        color: #5454d4;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.56px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:hover{
          border: 1px solid #c31a12;
          background-color: #f04037;
          color: #fff;
        }
      }
    }
    }
  }

 