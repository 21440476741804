
 
  .case-study-card{
    transition: .4s;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    .card-img{
      border-top-left-radius:8px;
      border-top-right-radius:8px;
      border-bottom-right-radius:0px;
      border-bottom-left-radius:0px;
      overflow: hidden;
      position: relative;
      >img{
        width: 100%;
        @include mobile-lg{
          width: auto;
        }
      }
      .brand-img {
        position: absolute;
        bottom: 28px;
        left: 30px;
        img{
          border-radius: 8px;
          border: 1px solid #eae9f2;
        }
      }
    }
    .card-content{
      padding-top: 35px;
      padding-bottom: 35px;
      padding-left: 35px;
      padding-right: 35px;
      border-left: 1px solid #eae9f2;
      border-right: 1px solid #eae9f2;
      border-bottom: 1px solid #eae9f2;
      background-color: #ffffff;
      border-bottom-left-radius:8px;
      border-bottom-right-radius:8px;
      .title{
        color: #19191b;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.66px;
        margin-bottom: 13px;
      }
      p{
        color: #767581;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 26px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    &:hover{
      box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
    }

    &-2{
      transition: .4s;
      border-bottom-left-radius:8px;
      border-bottom-right-radius:8px;
      @include mobile{
        display: flex;
      }
    .card-img{
      border-radius:8px;
      overflow: hidden;
      position: relative;
      @include tablet{
        max-width: 210px;
      }
      >img{
        width: 100%;
        @include mobile-lg{
          width: auto;
        }
      }
    }
    .card-content{
      padding-top: 30px;
      @include mobile{
        padding-left: 30px;
        padding-top: 0px;
      }
      .brand-img{
        margin-bottom: 22px;
        img{
          border-radius: 8px;
          border: 1px solid #eae9f2;
        }
      }
      .title{
        color: #19191b;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.66px;
        margin-bottom: 13px;
      }
      p{
        color: #767581;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 26px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  }


  .case-study-details-section{
    border-bottom: 1px solid #eae9f2;
    padding-bottom: 50px;
    @include tablet{
      padding-bottom: 120px;
    }
  }
  .case-study-details__big-img{
    border-radius: 10px;
    overflow: hidden;
  }
  .case-study-details__details-image-half{
    border-radius: 10px;
    overflow: hidden;
  }
  .case-study-details__text-block{
    .title{
      color: #19191b;
      font-size: 21px;
      font-weight: 700;
      letter-spacing: -0.66px;
      line-height: 28px;
      padding-bottom:22px ;
    }
    p{
      color: #696871;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 28px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .details-list{
      li{
        color: #696871;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.5px;
        line-height: 28px;
        margin-left: 20px;
        margin-bottom: 17px;
        display: flex;
        &:last-child{
          margin-bottom: 0;
        }
        &::before{
          content: "";
          min-width: 10px;
          max-width: 10px;
          min-height: 10px;
          max-height: 10px;
          background-color: #19191b;
          display: block;
          border-radius:500px ;
          margin-right: 15px;
          margin-top: 10px;
        }
      }
    }
    .btn{
      min-width: 250px;
      min-height: 60px;
      border-radius: 10px;
      border: 1px solid #c31a12;
      background-color: #f04037;
      overflow: hidden;
    }
  }