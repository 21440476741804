.page-banner{
  padding-top: 20px;
  padding-bottom: 30px;
  @include tablet{
    padding-top: 100px;
    padding-bottom: 80px;
  }
  .banner-content{
    .title{
      color: #19191b;
      font-weight: 700;
      letter-spacing: -2.5px;
      font-size: 34px;
      line-height: 46px;
      padding-bottom: 12;
      @include tablet{
        font-size: 60px;
        line-height: 70px;
        // padding-bottom: 22px;
      }
    }
    p{
      color: #696871;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
      margin-top: 5px;
    }
  }
}


.inner-hero-area{
  padding-top: 100px;
  padding-bottom: 50px;
  @include tablet{
    padding-top: 190px;
  padding-bottom: 180px;
  }
  &.bg-gradient{
    background-image: linear-gradient(147deg, rgba(141, 141, 236, 0.17) 0%, rgba(84, 84, 212, 0) 100%);
  }
  .inner-hero-text{
    .title{
      color: #19191b;
      font-weight: 700;
      letter-spacing: -2.5px;
      line-height: 1;
      margin-bottom: 15px;
      font-size: 34px;
      line-height: 46px;
      @include tablet{
        margin-bottom: 32px;
        font-size: 80px;
      }
    }
    p{
      color: #696871;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
    }
  }
 
}
.page-banner{
  padding-top: 20px;
  padding-bottom: 30px;
  @include tablet{
    padding-top: 100px;
    padding-bottom: 80px;
  }
  .banner-content{
    .title{
      color: #19191b;
      font-weight: 700;
      letter-spacing: -2.5px;
      font-size: 34px;
      line-height: 46px;
      padding-bottom: 12;
      @include tablet{
        font-size: 80px;
        line-height: 84px;
        padding-bottom: 22px;
      }
    }
    p{
      color: #696871;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
      margin-top: 5px;
    }
  }
}