  .site-navbar{
    padding: 10px 0 10px 0;
  }
  .header-btns {
    @include desktops {
      margin-left: 10px;
    }
  }
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 22px;
    width: 60px;
    height: 50px;
    display: flex;
    justify-content: center;
    border: none;
    font-weight: 700;
    i{
      color: #000;
    }
  }
  .main-menu{
    @include desktops{
      display: flex;
      justify-content: flex-end;
    }
    >li{
      >.nav-link{
        @include desktops{
          color: #353638!important;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          padding-top: 18px!important;
          padding-bottom: 18px!important;
          padding-left: 18px!important;
          padding-right: 18px!important;
  
        }
        &:hover{
          color: $l1-primary-color!important;
        }
      }
    }
    .nav-item.dropdown{
     @include desktops{
      position: relative;
      z-index: 99;
     }
     &:hover{
      >.menu-dropdown{
        @include desktops{
          top: 90%;
          opacity: 1;
          pointer-events: visible;
        }
      }
     }
    }
    
    
  }
  
  .offcanvas-active{
    
    &.navbar-expand-lg{
      .btn-close-off-canvas{
        @include desktops{
          display: none;
        }
      }
      @include till-desktop{
        .navbar-collapse{
          display: block;
          position: fixed;
          top: 0;
          background: #fff;
          left: -100%;
          padding-left: 20px;
          padding-right: 20px;
          height: 100%;
          min-height: 100vh;
          transition:left .4s;
          z-index: 9999;
          box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
          padding-top: 50px;
          width: 250px;
          overflow: auto;
          @include brk-point(380px) {
            width: 300px;
          }
          @include mobile-lg {
            width: 350px;
          }
          &.show{
            left: 0%;
            ~.btn-close-off-canvas{
              .icon-burger{
                display: none;
              }
              .icon-close{
                display: block;
              }
            }
          }
          &.collapsing{
            transition:height 0s;
            height: 100%;
          }
        }
        .btn-close-off-canvas{
          .icon-burger{
            display: block;
          }
          .icon-close{
            display: none;
          }
        }
        .main-menu {
          >li {
            padding-bottom: 0px;
            margin-bottom: 0px;
              border-bottom: 1px solid #ECECEC;
              >.nav-link{
                padding-bottom: 13px;
                padding-top: 13px;
              }
              .menu-dropdown {
                border: 0;
                border-radius: 0;
                min-width: auto;
                padding: 0;
                  >li {
                      padding-top:0 ;
                      padding-bottom:0 ;
                      border-top: 1px solid #ECECEC;
                      padding-left: 20px;
                      a{
                        padding-top: 13px;
                        padding-bottom: 13px;
                      }
                      &.single-item{
                        h3 {
                            font-size: 15px;
                            margin-bottom: 5px;
                            font-weight: 600;
                            line-height: 1;
                        }
                        p{
                            font-size: 13px;
                        }
                        a{
                            &:hover{
                                color: inherit;
                            }
                        }
                     }
                  }
              }
              &:last-child {
                border-bottom-color: transparent;
              }
          }
          li {
            i {
              margin-left: 8px;
              position: relative;
              top: 3px;
            }
            &:hover {
                >a {
                    color: $l1-primary-color;
                }
            }
          }
          a {
              display: flex;
          }
        }
  
      }
  
      
    }
    
  }

  .toggler-white{
    color: #fff!important;
    border-color: #fff!important;
  }


  .sticky-header{
    &:not(.mobile-sticky){
      position: absolute!important;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;
      @include desktops{
        position: fixed!important;
        transition: .4s;
        &.scrolling{
          transform: translateY(-100%);
          transition: .4s;
          
        }
        &.reveal-header{
          transform: translateY(0%);
          box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
          z-index: 9999;
        }
      }
    }
    &.mobile-sticky{
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;
      position: fixed!important;
      transition: .4s;
      &.scrolling{
        transform: translateY(-100%);
        transition: .4s;
        
      }
      &.reveal-header{
        transform: translateY(0%);
        box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
        z-index: 9999;
      }
    }
  }


  .sticky-header.reveal-header{
    background: #fff;
  }
  .landing-2{
    .sticky-header.reveal-header{
      background: #fff;
    }
  }
  .landing-3{
    
  }
  .landing-4{
    .sticky-header.reveal-header{
      background: #5454d4;
    }
  }
  .landing-5{
    
  }
  .landing-6{
    .sticky-header.reveal-header{
      background: #fff;
    }
  }
  .landing-7{
    .sticky-header.reveal-header{
      background: #fff;
    }
  }
  .landing-8{
   
  }

  .header-1{
    .header-btns{
      margin-right: 10px;
      @include mobile{
        margin-right: 15px;
      }
      @include desktops{
        margin-right: 0;
      }
      a{
          font-size: 14px!important;
          width: 120px!important;
          height: 34px!important;
          @include brk-point(370px){
            font-size: 16px !important;
            width: 141px !important;
            height: 45px !important;
          }
      }
    }
  }
  .header-3{
    .main-menu .menu-dropdown .single-item a p{
      color:$l3-primary-heading!important;
    }
    &.sticky-header.reveal-header{
      background: #fff;
    }
    
  }
  .header-4{
    &.sticky-header.reveal-header{
      background: #5454d4;
    }
    .btn-close-off-canvas.white-toggler{
      color: #fff;
      border-color: #fff;
    }
    .main-menu > li > .nav-link{
     @include desktops{
      color: rgba(255, 255, 255, 0.7)!important;
      font-weight: 300;
     }
    }
    .menu-dropdown{
      @include desktops{
        border-top:3px solid $l4-primary-color;
      }
    }
    .header-cart{
      .cart{
        color: #fff;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        &-count{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          border-radius: 23px;
          background-color: $l4-primary-color;
          color: rgba(0, 0, 0, 0.79);
          font-size: 16px;
          font-weight: 500;
          letter-spacing: -0.5px;
          margin-left: 13px;
        }
      }
    }
  }
  .header-5{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    .main-menu > li > .nav-link{
     @include desktops{
      color:#f7f7fb!important;
      font-weight: 300;
      &:hover{
        color: $theme-red-color!important;
      }
     }
    }
    .menu-dropdown{
      @include desktops{
        border-top:3px solid #FEDC5A;
      }
    }
    .header-button{
      a{
        font-size: 14px;
          max-width: 110px;
          height: 34px;
          @include mobile{        
            font-size: 16px;
            max-width: 140px;
            height: 45px;
          }
      }
    }
    &.sticky-header.reveal-header{
      background: radial-gradient(circle 961px at 49.94% 0%, #504a93 0%, #413e65 100%);
    }
  }
  .dark-theme{
    .header-3{
      &.sticky-header.reveal-header{
        background: #19191b;
      }
      
      .header-btn{
        a{
            font-size: 14px;
            max-width: 120px;
            height: 34px;
            display: none;
            @include brk-point(370px){
              display: inline-flex;
              max-width: 120px;
              height: 34px;
            }
            @include mobile{        
              font-size: 21px;
              max-width: 220px;
              height: 60px;
            }
        }
      }
    }
  }
  .header-6{
    .btn-close-off-canvas.white-toggler{
      color: #fff;
      border-color: #fff;
    }
    .main-menu > li > .nav-link{
      @include desktops{
        color: $l6-primary-heading!important;
        font-weight: 300;
        &:hover{
          color: $l6-secondary-color!important;
        }
      }
    }
    .menu-dropdown{
      @include desktops{
        border-top:3px solid #fedc5a;
      }
    }
  
    .header-btn{
      a{
        font-size: 14px;
        min-width: 110px;
        height: 34px;
        @include mobile{        
          font-size: 16px;
          min-width: 140px;
          height: 45px;
        }
      }
    }
  }

  .header-7{
    .header-btns{
      margin-right: 10px;
      @include mobile{
        margin-right: 15px;
      }
      @include desktops{
        margin-right: 0;
      }
      a{
          font-size: 14px!important;
          width: 120px!important;
          height: 34px!important;
          @include brk-point(370px){
            font-size: 16px !important;
            width: 141px !important;
            height: 45px !important;
          }
      }
    }
  }
  .header-8{
    .header-btns{
      margin-right: 10px;
      @include mobile{
        margin-right: 15px;
      }
      @include desktops{
        margin-right: 0;
      }
      a{
          font-size: 14px!important;
          width: 120px!important;
          height: 34px!important;
          @include brk-point(370px){
            font-size: 16px !important;
            width: 141px !important;
            height: 45px !important;
          }
      }
    }
    .main-menu > li > .nav-link{
      font-weight: 300;
    }
    &.sticky-header.reveal-header{
      background: #19191b;
    }
  }


  .absolute-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }