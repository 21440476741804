.career-details-page{
  .section-title{
    h2{
      color: $theme-primary-heading;
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -0.83px;
      line-height: 56px;
    }
    p{
      color: #7C8087;
      font-size: 18px;
      font-weight: 300;
      line-height: 32px;
      padding-top: 15px;
      +p{
        padding-top: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
.job-details-content{
  padding-top: 35px;
  padding-bottom: 40px;
  @include tablet{
    padding-top: 65px;
    padding-bottom: 100px;
  }
  .block-title{
    color: $theme-primary-heading;
    font-size: 21px;
    font-weight: 700;
    line-height: 34px;
  }
  .skill-list{
    li{
      color: $theme-primary-text;
      list-style: none;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      padding-top: 12.5px;
      padding-bottom: 12.5px;
      &:last-child{
        padding-bottom: 0;
      }
      &:first-child{
        padding-top: 0;
      }
      &:before{
        display: inline-block;
        content: "";
        width: 9px;
        height: 9px;
        background-color: $theme-primary-heading;
        border-radius: 500px;
        margin-right: 12px;
      }
    }
  }
  p{
    color: $theme-primary-text;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .job-top-header{
    max-width: 275px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-bottom: 30px;
    .location{
      color: #8C97AC;
      font-size: 16px;
      font-weight: 300;
      margin-right: 5px;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    
    .badge{
      height: 30px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 300;
      display: flex;
      align-items: center;
      padding-left: 13px;
      padding-right: 13px;
      width: 76px;
      &.green{
        background-color: rgba(15, 188, 73, 0.1);
        color: #0FBC49;
      }
      &.orange{
        background-color:rgba(245, 166, 35, 0.15);;
        color: #F5A623;
      }
      &.blue{
        background-color: rgba(16, 66, 253, 0.15);
        color: #1042FD;
      }
      &.purple{
        background-color: rgba($theme-secondary-color, 0.15);
        color: $theme-secondary-color;
      }
    }
  }
  .contant-block-1{
    .main-title{
      color: $theme-primary-heading;
      // font-family: "Circular Std Bold";
      font-weight: 700;
      letter-spacing: -1px;
      margin-bottom: 75px;
      font-size: 34px;
      line-height: 46px;
      @include tablet{
        font-size: 80px;
        line-height: 85px;
      }
    }
  }
 
  .apply-btn{
    .btn--primary{
      min-width: 250px;
      height: 60px;
      border-radius: 10px;
      border: 1px solid #C31A12;
      background-color: $theme-primary-color;
      overflow: hidden;
    }
  }
}
