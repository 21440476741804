  /*>>>>>>>> Pricing section styling (landing-6)<<<<<<<<<*/
  .omga-05__pricing-section {
    background-color: #F7F7FB;
    position: relative;
    padding-top: 90px;
    &:before{
      content: "";
      left: 0;
      bottom: 0;
      height: 30%;
      width: 100%;
      position: absolute;
      background: $l5-primary-heading;
    }
  }
  .pricing-wrapper{
    &.monthly-active{
      .price-block{
        .price{
          &:before{
            display: inline-block;
            content:attr(data-monthly) ;
          }
        }
      }
    }
    &.yearly-active{
      .price-block{
        .price{
          &:before{
            display: inline-block;
            content:attr(data-yearly) ;
          }
        }
      }
    }
    .pricing-btn{
      display: inline-flex;
      position: relative;
      margin-top: 55px;
      @include brk-point(470px){
        margin-top: 35px;
      }
      .period{
        color: $l5-primary-text;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: -0.5 6px;
        line-height: 28px;
      }
      .offer-badge{
        position: absolute;
        width: 85px;
        height: 29px;
        color: #fff;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: -0.41px;
        border-radius: 5px;
        background-color: $theme-red-color;
        display: flex;
        align-items: center;
        justify-content:center;
        top: 0;
        right: 0;
        transform: translateX(0%) translateY(-100%);
        margin-right: 0;
        margin-top: -10px;
        @include brk-point(470px){
          margin-top: 0px;
          top: 0;
            right: -25px;
          transform: translateX(100%) translateY(0%);   
          margin-right:10px;
        }
      }
      .toggle-btn{
        width: 72px;
        height: 33px;
        border-radius: 17px;
        background-color: #EAE9F2;
        position: relative;
        margin: 0 15px;
        span{
          width: 21px;
          height: 21px;
          background-color: #FFFFFF;
          position: absolute;
          left: 0;
          margin-left: 6px;
          top: 0;
          margin-top: 6px;
          transition: .4s;;
          border-radius: 500px;
          pointer-events:none;
        }
        &.clicked{
          background: $l5-secondary-color;;
          span{
            left: calc(100% - 33px);
          }
        }
      }
    }
  }
  .pricing-card--5 {
    box-shadow: 0 52px 54px rgba(65, 62, 101, 0.1);
    border-radius: 10px;
    background-color: #fff;
    padding-top: 30px;
    .price-content{
      padding-bottom: 30px;
      .small-title{
        color: $l5-secondary-color;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 28px;
      }
    }
    .price-block{
      display: flex;
      align-items: flex-end;
      justify-content: center;
      span{
        color: $l5-primary-heading;
        font-size: 30px;
        font-weight: 300;
        letter-spacing: -0.52px;
        line-height: 56px;
        margin-bottom: 5px;
      }
      .price{
        font-size: 80px;
        letter-spacing: -1.38px;
        color: $l5-primary-heading;
        &:before{
          display: inline-block;
          content:attr(data-active) ;
        }
      }
    }
    .price-bottom-text{
      color: $l5-primary-text;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      line-height: 28px;
      margin-top: 3px;
    }
    .card-list{
      max-width: 315px;
      margin: 30px auto 0;
      li{
        color: $l5-primary-heading;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: -0.66px;
        line-height: 50px;
        display: flex;
        &:before{
          content: "\f00c";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          display: inline-block;
          font-size: 13px;
          width: 30px;
          height: 30px;
          background-color: rgba(84, 84, 212, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 500px;
          color: $l5-secondary-color;
          position: relative;
          top: 9px;
          margin-right: 13px;
        }
      }
    }
    .price-btn{
      padding-top: 18px;
      padding-bottom: 22px;
      border-radius: 0 0 10px 10px;
      background-color: $l5-secondary-color;
      color: #fff;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: -0.66px;
      display: block;
      position: relative;
      z-index: 1;
      &:before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#fff,.1);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transition-property: transform;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover:before, &:focus:before,&:active:before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
    }
  }




  /*>>>>>>>> Pricing section styling (landing-6)<<<<<<<<<*/
  
  .omga-06__pricing-section{
    position: relative;
    background-color: $theme-ash-color;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 40px;
    @include tablet{
      padding-top: 100px;
      padding-bottom: 90px;
    }
  }
  
  .pricing-card--1{
    background: #fff;
    padding-top: 45px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    min-height: 441px;
    .title-top{
      color: $l6-secondary-color;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 28px;
      margin-bottom: 21px;
    }
    .title-bottom{
      color: $l6-primary-text;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      line-height: 28px;
      padding-top: 5px;
    }
    .pricing-title{
      color: #1d293f;
      font-size: 60px;
      font-weight: 700;
      letter-spacing: -1.03px;
      line-height: 56px;
    }
    p{
      color: $l6-primary-heading;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
      margin-top: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .form-group{
      margin-bottom: 0;
      padding-top: 25px;
      .form-control{
        border-radius: 10px;
        height: 60px;
        border: 1px solid #eae9f2;
        background-color: #f7f7fb;
        color: $l6-primary-text;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: -0.56px;
        text-align: center;
        margin-bottom: 15px;
      }
      .icon-input{
        position: relative;
        i{
          color: #cdccd9;
          position: absolute;
          height: 100%;
          top: 0;
          padding-left: 15px;
          padding-right: 15px;
          width: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .card-btn{
        margin-top: 0;
      }
    }
    .card-btn{
      height: 60px;
      border-radius: 5px;
      color: #fff;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: -0.66px;
      width: 100%;
      transform: perspective(1px) translateZ(0);
      position: relative;
      z-index: 1;
      overflow: hidden;
      &:before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#fff,.1);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-transition-property: transform;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover:before, &:focus:before,&:active:before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      }
      &.btn--blue{
        border: 1px solid $theme-blue-color-3;
        background-color: $l6-secondary-color;
      }
      &.btn--orange{
        border: 1px solid $theme-red-color-2;
        background-color: $l6-primary-color;
      }
    }
    &.premium{
      display: flex;
      flex-direction: column;
      .card-btn{
        margin-top: auto;
      }
    }
  }
  
/*>>>>>>>> Pricing section styling (pricing-page)<<<<<<<<<*/
  
  .pricing-card--2{
    border-radius: 10px;
    border: 1px solid #eae9f2;
    background-color: #fff;
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    padding-top: 32px;
    .small-title{
      color: #696871;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      line-height: 28px;
      margin-bottom: 22px;
    }
    .price{
      color: #1d293f;
      font-size: 60px;
      font-weight: 700;
      letter-spacing: -1.03px;
      line-height: 56px;
      color: #1d293f;
      margin-bottom: 30px;
      .time{
        font-size: 28px;
        font-weight: 300;
        letter-spacing: -1.03px;
        line-height: 1;
      }
    }
    .pricing-list{
      color: #696871;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 1.6;
      li{
        margin-bottom: 14px;
      }
    }
    .pricing-btn{
      padding-top: 30px;
      .btn{
        width: 100%;
        max-width:305px;
        border-radius: 10px;
        border: 1px solid #eae9f2;
        background-color: #ffffff;
        color: #5454d4;
        font-size: 21px;
        font-weight: 500;
        letter-spacing: -0.66px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }