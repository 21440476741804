
/*>>>>>>>> Team area styling <<<<<<<<<*/

.team-widget-1{
  text-align: center;
  .widget-image{
    max-width: 160px;
    min-width: 160px;
    min-height: 160px;
    max-height: 160px;
    overflow: hidden;
    border-radius: 500px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 29px;
  }
  .widget-text{
    .title{
      color: #19191b;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.75px;
      line-height: 1;
    }
    .subtitle{
      padding-top: 9px;
      color: #696871;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height:1;
      // text-transform: uppercase;
    }
  }
}