/*>>>>>>>> Video area styling 1<<<<<<<<<*/
.omga__video-area-1{
  position: relative;
  .video-image{
    width: 100%;
  }
  .play-button{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    
    img{
      max-width: 80%;
      @include mobile{
        max-width: 60%;
      }
      @include mobile-lg{
        max-width: 60%;
      }
      @include tablet{
        max-width: 100%;
      }
    }
  }
}
  
/*>>>>>>>> Video area styling 2<<<<<<<<<*/
  .omga__video-area-2{
    padding-top: 40px;
    @include tablet{
      padding-top: 120px;
    }
    .section-title{
     
      @include tablet{
        margin-bottom: 65px;
      }
      @include desktops{
        display: flex;
        justify-content: space-between;
      }
      .title{
        color: #19191b;
        font-weight: 700;
        letter-spacing: -2.5px;
        font-size: 34px;
        line-height: 46px;
        @include tablet{
          font-size: 80px;
        line-height: 84px;
        }
        @include desktops{
          margin-right: 40px;
          max-width: 46%;
          // margin-bottom: ;
        }
      }
      p{
        color: #696871;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: -0.66px;
        line-height: 39px;
      }
      .right-side{
        @include desktops{
          max-width: 40%;
          display: flex;
          align-items: flex-end;
        }
        p{
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  
    .video-image{
      position: relative;
      // box-shadow: 0 42px 54px rgba(0, 0, 0, 0.31);
      img{
        border-radius: 10px;
      }
      a{
        font-size: 22px;
        background-color: #5454D4;
        border-radius: 500px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: #fff;
        width: 64px;
        height: 64px;
        @include mobile-lg{
          width: 100px;
          height: 100px;
        }
        @include tablet{
          width: 124px;
          height: 124px;
        }
      }
    }
  }
  